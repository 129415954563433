<template>
  <div>
    <v-data-table
      :items="customer_payments"
      :headers="headers"
      :search="search"
      :loading="loading"
      dense
      :items-per-page="200"
      sort-by="payment_number"
      :sort-desc="true"
    >
      <!--Filters-->
      <template v-slot:top>
        <TableSummaryAlert
          :title="'Payments'"
          :total="paymentTotal"
          :itemCount="customer_payments.length"
        />
      </template>
      <!--Totals-->
      <template v-slot:item.payment_amount="{ item }">
        {{ formatAsCurrency("R", item.payment_amount) }}
      </template>
      <!--Related To-->
      <template v-slot:item.related_to="{ item }">
        <p class="mt-3" style="max-width: 300px; width: 300px">
          <!--Show Orders -->
          <span v-if="!!item.sales_orders">
            <span
              v-for="el in item.sales_orders"
              :key="el.order_id"
              class="mr-3"
            >
              <a @click="viewSalesOrder(el)">{{ el.order_number }}</a>
            </span>
          </span>
          <!--Show invoices-->
          <span v-if="!!item.invoices">
            <span v-for="el in item.invoices" :key="el.invoice_id" class="mr-3">
              <a>{{ el.invoice_number }}</a>
            </span>
          </span>
        </p>
      </template>
      <!--Empty-->
      <template v-slot:no-data>
        <EmptyTableSlot :type="'Payments'" />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import db from "../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../composables/external";
export default {
  name: "CustomerPaymentsTable",
  props: ["customer_id"],
  components: {
    TableSummaryAlert: () => import("../Alerts/TableSummaryAlert.vue"),
    EmptyTableSlot: () => import("../Tables/EmptyTableSlot.vue"),
  },
  data() {
    return {
      search: "",
      loading: false,
      customer_payments: [],
      headers: [
        { text: "Payment Number", value: "payment_number", align: "start" },
        { text: "Date", value: "payment_date" },
        { text: "Related To", value: "related_to" },
        { text: "Amount", value: "payment_amount", align: "right" },
      ],
    };
  },
  computed: {
    paymentTotal() {
      return this.customer_payments.reduce(
        (total, item) => total + item.payment_amount,
        0
      );
    },
  },
  created() {
    this.getPayments();
  },
  methods: {
    formatAsCurrency,
    getPayments() {
      this.loading = true;
      const docRef = db
        .collection("customer_payments")
        .where("customer.customer_id", "==", this.customer_id);
      docRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.customer_payments.push(data);
          });
          this.loading = false;
        })
        .catch((error) => error);
    },
    // Click to view ORDER
    viewSalesOrder(item) {
      const routeData = this.$router.resolve({
        path: `/view-order/${item.order_id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>